<template>
  <div>
    <div @click="createShow">
      <slot></slot>
    </div>
    <el-dialog width="30rem" append-to-body title="创建激活码" :visible.sync="show">
      <el-form label-width="100px" label-position="left">
        <el-form-item v-if="showInfo.id > 0" label="激活码归属人">
          <div class="flex-def flex-cCenter">
            <img v-if="showInfo.avatar" :src="showInfo.avatar | tomedia"
                 style="height: 3rem;width: 3rem;border-radius: 5px">
            <span style="margin-left: 5px">{{ showInfo.nickname }}</span>
            <span style="margin-left: 5px">({{ showInfo.id }})</span>
            <span style="margin-left: 5px">{{ showInfo.phone }}</span>
          </div>
        </el-form-item>
        <el-form-item label="激活码前缀">
          <el-input v-model="editForm.code_prefix"></el-input>
        </el-form-item>
        <el-form-item label="生成数量">
          <el-input-number v-model="editForm.code_num"></el-input-number>
        </el-form-item>
        <el-form-item label="激活码长度">
          <el-input-number v-model="editForm.code_len"></el-input-number>
        </el-form-item>
        <el-form-item label="激活码编号">
          从
          <el-input-number v-model="editForm.code_sn_id_start"></el-input-number>
          开始
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="start" type="danger">开始创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let ShowInfoTpl = {
  id: 0,
  nickname: "",
  avatar: "",
  phone: ""
}
let EditFormTpl = {
  creator_uid: 0,
  code_prefix: "",
  code_num: 0,
  code_len: 0,
  code_sn_id_start: 0,
}
export default {
  name: "create-code",
  props: {
    uid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      showInfo: {...ShowInfoTpl},
      editForm: {...EditFormTpl}
    }
  },
  mounted() {

  },
  methods: {
    userInfo() {
      if (!this.uid) return this.showInfo = {...ShowInfoTpl};
      this.$u.api.user.one({id: this.uid}).then(res => {
        this.showInfo = res;
      })
    },
    createShow() {
      this.userInfo();
      this.editForm = {...EditFormTpl};
      this.show = true;
    },
    start() {
      this.$u.api.card.plugin.cdkey.create({
        ...this.editForm,
        creator_uid:this.uid,
      }).then(() => {
        this.$message.success("操作成功");
        this.$emit("created");
        this.show = false;
      })
    }
  }
}
</script>

<style scoped>

</style>